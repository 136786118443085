import { format } from 'date-fns'
import Link from 'next/link'
import { Dispatch, MutableRefObject, SetStateAction } from 'react'
import { Entity, Goal, ImpactDataForCompare, Portfolio } from '@netpurpose/api'
import {
  badgeContent,
  ComparisonSpot,
  Icon,
  LinkText,
  PortfolioBadge,
  TooltipContent,
} from '@netpurpose/np-ui'
import { isNumber } from '@netpurpose/types'
import { formatNumberWithThousandGroups, formatRatioAsPercent } from '@netpurpose/utils'
import { ColumnTitleWithInfoTooltip } from '#components/common/ColumnTitleWithInfoTooltip'
import { ImpactValue } from '#components/impact/ImpactValue'
import { MetricNameWithInteraction } from '#components/MetricNameWithInteraction'
import { AnalyticsContextType } from '#context/analytics'
import { AnalyticsButtonName, AnalyticsEventName } from '#types/analytics'
import {
  ExpandIconContainer,
  IntensityValue,
  Placeholder,
  TableHeader,
  TypeContainer,
  ValuesContainer,
} from '../Compare.style'

export type CompareSummaryData =
  | (Entity & { dataType: 'company'; key: string })
  | (Portfolio & {
      dataType: 'portfolio'
      key: string
    })

export type ComparePerformance = 'best' | 'worst' | null | 'n/a'

type ImpactDataForCompareWithPerformance = Pick<
  ImpactDataForCompare[string][number],
  'value' | 'intensity' | 'yearOnYear' | 'factoidOrigin' | 'isNd'
> & {
  performance: ComparePerformance
}

export type CompareImpactData = {
  questionId: number
  name: string
  unit: string
  goal: Goal | undefined
  portfolios: {
    [portfolioId: number]: ImpactDataForCompareWithPerformance
  }
  companies: {
    [entityId: number]: ImpactDataForCompareWithPerformance
  }
}

const getTitle = (summaryData: CompareSummaryData) => {
  const yesterdaysDate = new Date(Date.now() - 864e5)

  if (summaryData.dataType === 'company') {
    return (
      <ValuesContainer>
        <TypeContainer>
          <PortfolioBadge type="company" withoutTooltip />
          <span>{badgeContent.company.title}</span>
        </TypeContainer>
        <span>
          {(summaryData.marketCap ? '$' : '') +
            formatNumberWithThousandGroups(summaryData.marketCap, 0, '')}
        </span>
        <span>N/A</span>
        <span>{format(yesterdaysDate, 'MM-dd-yyyy')}</span>
        <span>N/A</span>
        <span>{summaryData?.hasResearch ? 'Yes' : 'No'}</span>
      </ValuesContainer>
    )
  }

  if (summaryData.dataType === 'portfolio') {
    const coverage = `${formatRatioAsPercent(summaryData?.valueCovered)} AUM / ${summaryData?.numHoldingsCovered}/${summaryData?.numHoldings} Holdings`
    const research = `${formatRatioAsPercent(summaryData?.valueResearched)} AUM / ${summaryData?.numHoldingsResearched}/${summaryData?.numHoldings} Holdings`

    return (
      <ValuesContainer>
        <TypeContainer>
          <PortfolioBadge type={summaryData.type} withoutTooltip />
          <span>{badgeContent[summaryData.type]?.title}</span>
        </TypeContainer>
        <span>{'$' + formatNumberWithThousandGroups(summaryData.value, 0)}</span>
        <span>{summaryData.numHoldings}</span>
        <span>{format(summaryData.valuationDate, 'MM-dd-yyyy')}</span>
        <span>{coverage}</span>
        <span>{research}</span>
      </ValuesContainer>
    )
  }

  return null
}

const getDataColumns = ({
  id,
  dataType,
  name,
}: {
  id: number
  dataType: 'company' | 'portfolio'
  name: string
}) => {
  if (dataType === 'company') {
    return [
      {
        title: (
          <ColumnTitleWithInfoTooltip
            title="Company"
            text={
              <TooltipContent content={[{ text: 'Unweighted values of issuing companies.' }]} />
            }
            logTooltipHoverEvent={() => null}
            withoutPadding
            align="center"
          />
        ),
        children: [
          {
            title: 'Value',
            align: 'right' as const,
            render: (record: CompareImpactData) => {
              const matchingQuestion = record.companies[id]

              if (!matchingQuestion) {
                return null
              }

              const { value } = matchingQuestion

              return (
                <ImpactValue
                  dataValue={{
                    ...(isNumber(value) ? { value } : {}),
                    unit: record.unit,
                  }}
                  questionId={record.questionId}
                  questionName={record.name}
                  isNd={matchingQuestion.isNd}
                  collectionType="entityKPI"
                  collectionName={name}
                  kpiDataType="Absolute"
                  entityId={id}
                  {...(matchingQuestion.factoidOrigin
                    ? { factoidOrigin: matchingQuestion.factoidOrigin }
                    : {})}
                />
              )
            },
          },
          {
            title: 'Per $M EVIC',
            align: 'right' as const,
            render: (record: CompareImpactData) => {
              const matchingQuestion = record.companies[id]

              if (!matchingQuestion) {
                return null
              }

              const { intensity, performance } = matchingQuestion

              return (
                <IntensityValue $performance={performance} title={intensity?.toString()}>
                  <ImpactValue
                    dataValue={{
                      ...(isNumber(intensity) ? { value: intensity } : {}),
                      unit: record.unit,
                    }}
                    questionId={record.questionId}
                    questionName={record.name}
                    isNd={matchingQuestion.isNd}
                    collectionType="entityKPI"
                    collectionName={name}
                    kpiDataType="Intensity"
                    entityId={id}
                    formatWithDynamicPrecision
                  />
                  {performance !== 'n/a' && (
                    <ComparisonSpot
                      tooltipText=""
                      goal={
                        performance === 'best'
                          ? Goal.INCREASE
                          : performance === 'worst'
                            ? Goal.DECREASE
                            : Goal.NEUTRAL
                      }
                      isIncrease
                    />
                  )}
                </IntensityValue>
              )
            },
          },
          {
            title: 'YoY Change',
            align: 'right' as const,
            className: 'border-right',
            render: (record: CompareImpactData) => {
              const matchingQuestion = record.companies[id]

              if (!matchingQuestion) {
                return null
              }

              const { yearOnYear } = matchingQuestion

              return (
                <ImpactValue
                  dataValue={{
                    ...(isNumber(yearOnYear) ? { value: yearOnYear } : {}),
                    unit: 'ratio',
                  }}
                  questionId={record.questionId}
                  questionName={record.name}
                  isNd={matchingQuestion.isNd}
                  collectionType="entityKPI"
                  collectionName={name}
                  kpiDataType="Year on year"
                  goal={record.goal}
                  entityId={id}
                />
              )
            },
          },
        ],
        className: 'border-right secondary-background',
        align: 'right' as const,
      },
    ]
  }

  return [
    {
      title: (
        <ColumnTitleWithInfoTooltip
          title="Company"
          text={<TooltipContent content={[{ text: 'Unweighted values of issuing companies.' }]} />}
          logTooltipHoverEvent={() => null}
          withoutPadding
          align="center"
        />
      ),
      children: [
        {
          title: 'Value',
          align: 'right' as const,
          className: 'border-right',
          render: (record: CompareImpactData) => {
            const matchingQuestion = record.portfolios[id]

            if (!matchingQuestion) {
              return null
            }

            const { value } = matchingQuestion

            return (
              <ImpactValue
                dataValue={{
                  ...(isNumber(value) ? { value } : {}),
                  unit: record.unit,
                }}
                questionId={record.questionId}
                questionName={record.name}
                isNd={matchingQuestion.isNd}
                collectionType="portfolioKPI"
                collectionName={name}
                kpiDataType="Absolute"
              />
            )
          },
        },
      ],
      className: 'border-right secondary-background',
      align: 'right' as const,
    },
    {
      title: (
        <ColumnTitleWithInfoTooltip
          title="Portfolio"
          text={
            <TooltipContent
              content={[
                { text: 'Weighted values of issuing companies attributed to your portfolio.' },
              ]}
            />
          }
          logTooltipHoverEvent={() => null}
          withoutPadding
          align="center"
        />
      ),
      children: [
        {
          title: 'Per $M Invested',
          align: 'right' as const,
          render: (record: CompareImpactData) => {
            const matchingQuestion = record.portfolios[id]

            if (!matchingQuestion) {
              return null
            }

            const { performance, intensity } = matchingQuestion

            return (
              <IntensityValue $performance={performance} title={intensity?.toString()}>
                <ImpactValue
                  dataValue={{
                    ...(isNumber(intensity) ? { value: intensity } : {}),
                    unit: record.unit,
                  }}
                  questionId={record.questionId}
                  questionName={record.name}
                  isNd={matchingQuestion.isNd}
                  collectionType="portfolioKPI"
                  collectionName={name}
                  kpiDataType="Intensity"
                  formatWithDynamicPrecision
                />
                {performance !== 'n/a' && (
                  <ComparisonSpot
                    tooltipText=""
                    goal={
                      performance === 'best'
                        ? Goal.INCREASE
                        : performance === 'worst'
                          ? Goal.DECREASE
                          : Goal.NEUTRAL
                    }
                    isIncrease
                  />
                )}
              </IntensityValue>
            )
          },
        },
        {
          title: 'YoY Change',
          align: 'right' as const,
          className: 'border-right',
          render: (record: CompareImpactData) => {
            const matchingQuestion = record.portfolios[id]

            if (!matchingQuestion) {
              return null
            }

            const { yearOnYear } = matchingQuestion

            return (
              <ImpactValue
                dataValue={{
                  ...(isNumber(yearOnYear) ? { value: yearOnYear } : {}),
                  unit: 'ratio',
                }}
                questionId={record.questionId}
                questionName={record.name}
                isNd={matchingQuestion.isNd}
                collectionType="portfolioKPI"
                collectionName={name}
                kpiDataType="Year on year"
                goal={record.goal}
              />
            )
          },
        },
      ],
      className: 'border-right secondary-background',
      align: 'right' as const,
    },
  ]
}

export const companyChildren = ({
  isExpanded,
  summaryData,
}: {
  isExpanded: boolean
  summaryData: CompareSummaryData | undefined
}) => {
  if (!summaryData) {
    return null
  }
  return {
    title: () => (isExpanded ? getTitle(summaryData) : null),
    children: getDataColumns({
      id: summaryData.id,
      dataType: summaryData.dataType,
      name: summaryData.name,
    }),
    className: 'border-right secondary-background',
  }
}

const placeholder = {
  title: () => <Placeholder />,
  width: '30%',
  className: 'placeholder',
}

export const getColumns = ({
  isExpanded,
  setIsExpanded,
  summaryData,
  compareValues,
  setCompareValues,
  analytics,
  metricCardRef,
  openMetricCardId,
  setOpenMetricCardId,
}: {
  isExpanded: boolean
  setIsExpanded: (isExpanded: boolean) => void
  summaryData: CompareSummaryData[] | undefined
  compareValues: { id: string; type: string }[]
  setCompareValues: (value: { id: string; type: string }[]) => void
  analytics: AnalyticsContextType
  metricCardRef: MutableRefObject<null>
  openMetricCardId: number | null
  setOpenMetricCardId: Dispatch<SetStateAction<number | null>>
}) => {
  const comparisonColumns = (summaryData || []).map((item) => ({
    title: () => {
      return (
        <TableHeader>
          <Link
            href={item.dataType === 'company' ? `/explore/${item.id}` : `/portfolios/${item.id}`}
          >
            <LinkText $bold>{item.name}</LinkText>
          </Link>
          <Icon
            icon="Delete"
            alt="delete"
            color="red"
            onClick={() => {
              analytics.logEvent(AnalyticsEventName.ButtonClick, {
                button_name: AnalyticsButtonName.CompareDeleteItem,
                ...(item.dataType === 'company'
                  ? { entity_id: item.id }
                  : { portfolio_id: item.id }),
              })
              setCompareValues(compareValues.filter((value) => value.id !== `${item.id}`))
            }}
          />
        </TableHeader>
      )
    },
    children: [
      companyChildren({ isExpanded, summaryData: summaryData?.find((d) => d.id === item.id) }),
    ],
    className: 'border-right primary-background',
  }))

  return [
    {
      title: () => (
        <ExpandIconContainer>
          {isExpanded ? (
            <Icon
              icon="MinusSquare"
              onClick={() => setIsExpanded(false)}
              alt="collapse"
              style={{ cursor: 'pointer' }}
            />
          ) : (
            <Icon
              icon="PlusSquare"
              onClick={() => setIsExpanded(true)}
              alt="expand"
              style={{ cursor: 'pointer' }}
            />
          )}
        </ExpandIconContainer>
      ),
      children: [
        {
          title: () =>
            isExpanded ? (
              <ValuesContainer $withLine>
                <span>Type</span>
                <span>Value</span>
                <span>Holdings</span>
                <span>Validation date</span>
                <span>Coverage</span>
                <span>Research</span>
              </ValuesContainer>
            ) : null,
          children: [
            {
              title: () => <></>,
              children: [
                {
                  title: 'Metric',
                  align: 'left',
                  render: (record: CompareImpactData) => (
                    <MetricNameWithInteraction
                      questionId={record.questionId}
                      isContributing
                      metricCardRef={metricCardRef}
                      openMetricCardId={openMetricCardId}
                      setOpenMetricCardId={setOpenMetricCardId}
                    >
                      {record.name}
                    </MetricNameWithInteraction>
                  ),
                  fixed: 'left' as const,
                },
                {
                  title: 'Units',
                  align: 'left',
                  className: 'border-right',
                  render: (record: CompareImpactData) => <span>{record.unit}</span>,
                  fixed: 'left' as const,
                },
              ],
              className: 'border-right secondary-background',
            },
          ],
          align: 'left' as const,
          className: 'border-right secondary-background',
        },
      ],
      className: 'border-right primary-background',
    },
    ...(comparisonColumns.length > 2
      ? comparisonColumns
      : [
          comparisonColumns[0] ?? placeholder,
          comparisonColumns[1] ?? placeholder,
          comparisonColumns[2] ?? placeholder,
        ]),
  ]
}
